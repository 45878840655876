@import 'src/styles/chart-data-dashboard';

.wrapper {
}

.filterWrapper {
  @extend #filterWrapper;
}

.additionalFilter {
  @extend #additionalFilter;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.additionalFilterLabel {
  @extend #additionalFilterLabel;
}

.select {
  @extend #select;
}

.chart {
  @extend #chartWrapper;
}

.overlayWrapper {
  position: relative;
}
