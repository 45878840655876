@import '../../../styles/config';
@import '../../../styles/grids';

.wrapper {
  width: 100%;
}

.dropdownWrapper {
  margin: 0 0 15px 100px;
}

.tileWrapper {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 10px));
  gap: 16px;
  margin-top: 24px;

  @include media('>=xlarge') {
    grid-template-columns: repeat(3, calc(33% - 7px));
  }

  @include media('>=xxlarge') {
    grid-template-columns: repeat(4, calc(25% - 12px));
  }
}

.sectionHeader {
  margin-bottom: 0;
}

.selectDropdownWrapper {
  margin-right: 32px;
}

.chartDashboardContainer {
  min-height: 1200px;
}
