@import '../../../styles/config.scss';

.wrapper {
  position: relative;

  &__withoutScroll {
    background-color: rgba(var(--colors-black), 0.75);
    padding: 16px 40px 32px;
    border-radius: 8px;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: rgb(var(--colors-gray-4));

  th {
    font-weight: 500;
  }

  td {
    color: rgb(var(--colors-white));
    font-weight: 400;
  }

  td, th {
    height: 56px;
    padding: 0 8px 0 0;

    &:first-of-type {
      padding-left: 8px;
    }
  }
}

.tbodyRow {
  border-top: 1px solid rgb(var(--colors-gray-5));

  &:hover + .tbodyRow {
    border-top: 1px solid transparent;
  }

  &:hover:not(.row__hover) {
    background-color: rgb(var(--colors-gray-7));
    border-top: 1px solid transparent;
  }

  &:first-of-type:not(:hover) {
    border-top: 1px solid rgb(var(--colors-gray-5));
  }

  &:first-of-type:not(.row__hover):hover {
    border-top: 1px solid transparent;
  }

  &:last-of-type:not(:hover) {
    border-bottom: 1px solid rgb(var(--colors-gray-5));
  }

  &:last-of-type:hover {
    border-bottom: 1px solid transparent;
  }
}

.tbodyRowExpanded {
  overflow: hidden;
  border: none;
  padding: 0;
}
