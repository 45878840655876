@import "../../../styles/config";
@import "../../../styles/grids";

.wrapper {
  padding-bottom: 50px;
}

.sectionHeader {
  margin: 100px 0 20px 0;
}

.companiesSection {
  @extend %companiesSection;
}
