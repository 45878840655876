.wrapper {
  border-radius: 8px;
  background-color: rgb(var(--colors-black));
}

.inner {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
  padding: 16px;
  max-height: 300px;
  opacity: 1;
  transition: max-height .2s linear, opacity .2s linear .2s;

  & + & {
    padding-top: 0;
  }

  &.collapsed {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0 16px;
    transition: all .2s linear;
  }
}

.item {
  & > * {
    width: 100%;
  }
}

.button {
  display: block;
  width: 100%;
  border: none;
  border-top: 1px solid rgb(var(--colors-gray-6));
  border-radius: 0 0 8px 8px;
  padding: 11px 0;
  text-align: center;
  font-size: 14px;
  color: rgb(var(--colors-white));
  background-color: transparent;
  cursor: pointer;
}

.select {
  [class*=value] {
    text-align: left;
    width: 100%;
  }

  [class*=dropdown] {
    width: 100%;
  }
}

.label {
  font-size: 14px;
  color: rgb(var(--colors-gray-3));
  margin-bottom: 8px;
}
