@import 'src/styles/chart-data-dashboard';

.wrapper {
}

.filterWrapper {
  @extend #filterWrapper;
}

.additionalFilter {
  @extend #additionalFilter;
}

.additionalFilterInner {
  max-width: 273px;
}

.additionalFilterLabel {
  @extend #additionalFilterLabel;
}

.select {
  @extend #select;
}

.barChart {
  @extend #chartWrapper;
}

.overlayWrapper {
  position: relative;
}
