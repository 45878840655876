.option {
  display: block;
  position: relative;
  width: 100%;
  padding: 8px 40px;
  color: rgb(var(--colors-black));
  background: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  border: none;
  cursor: pointer;
  text-align: left;
  word-break: break-word;

  &:hover {
    background-color: rgb(var(--colors-gray-1));
  }

  &.active {
    font-weight: 600;

    .optionIcon {
      opacity: 1;
    }
  }

  &.withSeparator {
    border-bottom: 1px solid rgb(var(--colors-gray-2));
  }
}

.optionIcon {
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 16px;
  margin-top: -8px;
  opacity: 0;
  color: rgb(var(--colors-lan-green));
}

// dirty fix to match the design for coming soon, to be removed

.disableOption {
  pointer-events: none;
}

.overrideContent {
  display: flex;
  align-items: baseline;
  color: rgb(var(--colors-gray-4));

  span {
    font-size: 12px;
    margin-left: 10px;
    color: rgb(var(--colors-lan-green));
  }
}
