.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider {
  position: relative;
  width: 100%;
  height: 3px;
  z-index: 1;
}

.track,
.range {
  position: absolute;
}

.track,
.range {
  height: 100%;
}

.track {
  background-color: rgb(var(--colors-white));
  width: 100%;
  z-index: 1;
  cursor: pointer;
}

.range {
  pointer-events: none;
  background-color: rgb(var(--colors-lan-green));
  z-index: 2;
}

.leftValue,
.rightValue {
  color: rgb(var(--colors-gray-3));
  font-size: 12px;
  width: 100px;
  text-align: center;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
}

.thumb__left {
  z-index: 3;
  left: -4px;
}

.thumb__right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: rgb(var(--colors-white));
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.4);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 13px;
  width: 13px;
  pointer-events: all;
  position: relative;
  transition: 0.2s ease;
  top: 2px;

  &:hover {
    transform: scale(1.2);
  }
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: rgb(var(--colors-white));
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.4);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 13px;
  width: 13px;
  pointer-events: all;
  position: relative;
  transition: 0.2s ease;
  top: 2px;

  &:hover {
    transform: scale(1.2);
  }
}
