#filterWrapper {
  margin-bottom: 14px;
  position: relative;
  z-index: 2;
}

#additionalFilter {
  background-color: rgb(var(--colors-black));
  border-radius: 8px 8px 0 0;
  padding: 24px;
  position: relative;
  z-index: 1;
}

#additionalFilterLabel {
  margin-bottom: 8px;
}

#select {
  margin-bottom: 16px;
  z-index: 5;

  [class*=value] {
    width: 100%;
    text-align: left;
  }

  [class*=dropdown] {
    width: 100%;
  }
}

#chartWrapper {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  padding-top: 20px;
}
